.loaders {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #1E7676;
    width: 80px;
    height: 80px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin: auto;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .div{
    display: flex;
     align-content: center;
     text-align: center;
     justify-content: center;
     justify-items: center;
     align-items: center;
     /* height: 100%; */
     margin-block: auto;
   }

   .loader{
    display: flex;
    align-items: center;
    justify-content: center;
   }