@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz,wght@0,9..144,100..900;1,9..144,100..900&family=Inter:wght@100..900&display=swap');


:root, body , html{
  margin: 0;
  font-family: 'Inter', sans-serif;
  /* font-family: "Fraunces", serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  scroll-behavior: auto;
  overflow-x: hidden;
  /* height: 100%; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, h1, div{
  margin: 0;
  padding: 0;
}

.section{
  @apply xl:px-[300px] md:py-20 py-6 px-4 md:px-32
}
.font-Fraunces{
  font-family: "Fraunces", serif; 
}
.big-text{
  @apply md:text-[54px] font-bold md:leading-[64px] text-[#061818] text-2xl;
}
.white-big-text{
  @apply md:text-[54px] font-bold md:leading-[64px] text-[#DEE1E3]  text-2xl;
}
.introduction{
 @apply md:text-[26px] md:leading-[40px] text-[#061818] font-Fraunces pt-6 text-lg
}
.white-introduction{
  @apply md:text-[26px] md:leading-[40px] text-[#C2C8CB] font-Fraunces pt-6 text-lg
}
.introductionwithoutpadding{
  @apply md:text-[26px] md:leading-[40px] text-[#061818] font-Fraunces text-lg
}
.greenSemiboldText{
  @apply text-[#11643C] font-semibold text-xl;
}
.greenSemiboldTextBase{
  @apply font-semibold text-base text-[#11643C];
}
.boldBlack{
  @apply font-bold text-[#061818] text-[22px] leading-7;
}
.blackTextBase{
  @apply  text-base text-[#061818];
}
.textSemiboldFraunces{
  @apply font-Fraunces md:text-[26px] md:leading-[52px] text-[#061818] font-semibold whitespace-normal text-lg;
}
.underTitle{
  @apply md:text-xl text-lg text-[#454545] text-center pt-4
}
.biggerFontFraunces{
  @apply md:text-[66px] md:leading-[76px] text-3xl font-semibold font-Fraunces
}
.text28{
  @apply md:text-[28px] text-sm font-Fraunces text-[#454545];
}
.textMediumBase{
  @apply md:text-base text-sm text-[#454545] pt-6 font-medium;
}
.screen300{
  @apply xl:px-[300px] md:px-32 px-4;
}
.section2{
  @apply xl:px-[284px] px-4 md:px-32;
}




.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* body, html {
  height: 200%;
  margin: 0;
} */

.color-change-div {
  transition: background-color 0.5s ease, color 0.5s ease;
  /* width: 100%;
  height: 100px; Adjust the height as needed */
}


.page {
  transition: background-color 0.5s ease, color 0.5s ease;
}

